import React from "react";
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";

import "./faq.scss";
import CirclePlus from "@components/circle-plus";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from "react-accessible-accordion";
import { graphql, StaticQuery } from "gatsby";

const FAQ = ({ data }) => {
	const { faq: { edges = {} } = {} } = data;
	const questions = edges
		.map(({ node }) => node.childMarkdownRemark.frontmatter)
		.sort((a, b) => a.order - b.order);
	return (
		<section className="full-width faq">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Часто задаваемые вопросы</h2>
					</div>
				</div>
			</div>
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<Accordion
							allowZeroExpanded
							allowMultipleExpanded
							className="accordion accordion_theme_faq"
						>
							{questions &&
								questions.map(({ question, answer, order }) => (
									<AccordionItem key={order}>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="container">
													<CirclePlus className="circle-plus" />
													<span>{question}</span>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<div className="markdown-faq">
												<ReactMarkdown source={answer} plugins={[breaks]} />
											</div>
										</AccordionItemPanel>
									</AccordionItem>
								))}
						</Accordion>
					</div>
				</div>
			</div>
		</section>
	);
};

FAQ.defaultProps = {
	data: {}
};

FAQ.propTypes = {};

export default props => (
	<StaticQuery
		query={graphql`
			query {
				faq: allFile(filter: { relativeDirectory: { eq: "faq" } }) {
					edges {
						node {
							childMarkdownRemark {
								frontmatter {
									order
									question
									answer
								}
							}
						}
					}
				}
			}
		`}
		render={data => <FAQ data={data} {...props} />}
	/>
);
